export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  liquidityMiningManager: {
    address: "0x1136970c3250bF893fc4b66A78D297Bda4C8DCa8",
  },
  escrowedReward: {
    address: "0x5564040f8dD5cc4E9Ae54e202bEACBBbedD8c81e",
  },
  view: {
    address: "0x9b415269fa83B4e5C14cBf00eFaa7462D108B9ab",
  },
  singleTokenPool: {
    address: "0xB00E2CeFA3B8C5950629a7485096Aabd9aC097A0",
  },
  lpPool: {
    address: "",
  },
  uniswapLpPairAddress : {
    address: ""
  },
  copperLaunchPoolContract:{
    address: "0xeEDcA0C2cBa983b718C66094fC8E41F9eD52F82a"
  },
  balancerVault: {
    address: "0xba12222222228d8ba445958a75a0704d566bf2c8",
    poolId: "0xeedca0c2cba983b718c66094fc8e41f9ed52f82a00020000000000000000011c"
  }
};
