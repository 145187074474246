export const pools = [
  {
    pid: 0,
    stakedTokenName: "DUET",
    stakedTokenSymbol: "DUET",
    rewardTokenName:"DUET",
    stakedTokenDecimals: 18,
    tokenAddress: "0xE8521b61f64Fc45A0bC3db36D2A524fe61a69b52",
    poolAddress: "0xB00E2CeFA3B8C5950629a7485096Aabd9aC097A0",
    getUrl:"https://app.balancer.fi/#/trade?outputCurrent=0xE8521b61f64Fc45A0bC3db36D2A524fe61a69b52",
    weight: 1,
    toFixed: 2
  }
];
