import { singleTokenStakingABI, contracts } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";

export const deposit = async ({
  web3,
  address,
  poolData,
  amount,
  duration,
  dispatch,
}) => {
  // here duration is week, need to multiple 7*24*60*60

  let lockPeriod =
    duration * 30 * 24 * 60 * 60 > 600 ? duration * 30 * 24 * 60 * 60 : 600;

  const contract = new web3.eth.Contract(
    singleTokenStakingABI,
    poolData.poolAddress
  );
  const data = await _deposit({
    web3,
    contract,
    amount,
    duration: lockPeriod,
    address,
    dispatch,
  });
  return data;
};

const _deposit = ({ web3, contract, amount, duration, address, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .deposit(amount, duration, address)
      .send({ from: address })
      .on("transactionHash", function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
            hash,
          })
        );
      })
      .on("receipt", function (receipt) {
        resolve();
      })
      .on("error", function (error) {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
