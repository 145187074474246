import React from "react";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Price from "./Price.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
const checkSelected = (location, linkTo) => {
  if (linkTo.length < 5) return location == "#" + linkTo;
  return location.indexOf("#" + linkTo) >= 0;
};
const renderListItem = (name, color, linkTo, location) => {
  const selected = checkSelected(location, linkTo);
  return (
    <LinkButton
      color={color}
      style={{ padding: 0 }}
      title={name}
      href={linkTo}
      selected={selected}
    />
  );
};
const MenuItems = ({
  handleDrawerToggle,
  style,
  footer = false,
  color = "white",
}) => {
  const { t } = useTranslation();
  let currentLocation = window.location.hash;
  return (
    <div style={style}>
      <Hidden mdUp>
        <ListItem
          button
          component={Link}
          to="/overview"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Overview")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/reward"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Rewards")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/leaderboard"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Leaderboard")} />
        </ListItem>
        <ConnectWallet textButton />
      </Hidden>
      <Hidden smDown>
        <div>
          <Box
            display="flex"
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ marginLeft: "-16px" }}
          >
            {renderListItem("Overview", color, "/", currentLocation)}
            {renderListItem("Rewards", color, "/reward", currentLocation)}
            {renderListItem("Leaderboard", "gray", "", currentLocation)}

            <Price />
            <span style={{ marginLeft: 0 }}>
              <ConnectWallet textButton />
            </span>
          </Box>
        </div>
      </Hidden>
    </div>
  );
};
const Href = styled.a`
  a:hover {
    text-decoration: none;
  }
`;
const ContainerDiv = styled.div`
  padding-left: 20px;
  a:hover {
    text-decoration: none;
  }
`;
const StyledDiv = styled.div`
  padding: 4px;
  margin: 2px 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => props.color};
  :hover {
  }
`;

function LinkButton({ style, title, href, color, selected }) {
  return (
    <ContainerDiv style={style}>
      {href ?
      (
        <Link
          to={href}
          style={{
            textDecoration: "none",
          }}
        >
          <StyledDiv
            color={color}
            style={{
              borderBottom: selected ? "2px solid rgba(236,42,56,0.6)" : "0px",
            }}
          >
            {title}
          </StyledDiv>
        </Link>)
      :(
        <StyledDiv
          color={color}
          style={{
            borderBottom: selected ? "2px solid rgba(236,42,56,0.6)" : "0px",
          }}
        >
          {title}
        </StyledDiv>
      )}
    </ContainerDiv>
  );
}

export default MenuItems;
