import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import banner from "assets/img/duet_banner.jpeg";
import discordIcon from "assets/img/discord.png";
import twitterIcon from "assets/img/twitter.png";
import gitbookIcon from "assets/img/gitBook.png";
import telegramIcon from "assets/img/telegram.png";
import token from "assets/img/duet_logo.png";
import { pools } from "features/configure";
import {
  useFetchDashboard,
  useFetchPoolDetails,
  useFetchRewardPoolDetails,
  useFetchWithdraw,
} from "./redux/hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFetchPrice } from "../price/redux/hooks";
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import CustomTable from "components/CustomTable/CustomTable.js";
import _ from "lodash";
import moment from "moment";
import PriceChart from "./components/PriceChart"

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    textAlign: "center",
    color: "white",
    fontSize: 40,
    lineHeight: 1.1,
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Overview = () => {
  const classes = useStyles();
  const theme = useTheme();
  const REWARD_PER_SECOND = convertAmountFromRawNumber(675154320987654);
  const SECONDS_PER_YEAR = 86400 * 30 * 12;
  const MAX_LOCKED_BOOSTED = 2;

  const data = _.find(pools, { pid: 0 });

  const { fetchDashboard, detail } = useFetchDashboard();
  const { fetchPrice, priceData, lpData } = useFetchPrice();
  const { poolDetails, fetchPoolDetails } = useFetchPoolDetails();
  const { rewardPoolDetails, fetchRewardPoolDetails } =
    useFetchRewardPoolDetails();
  const { fetchWithdraw } = useFetchWithdraw(data);

  const [userStaked, setUserStaked] = useState("");
  const [userUnclaimedRewards, setUserUnclaimedRewards] = useState("");
  const [userDeposits, setDeposits] = useState([]);
  const [totalStaked, setTotalStaked] = useState("");
  const [totalValueLocked, setTotalValueLocked] = useState("");

  const [totalValuedClaimed, setTotalValueClaimed] = useState("");
  const [poolTotalStaked, setPoolTotalStaked] = useState([]);
  const [poolTotalSupply, setPoolTotalSupply] = useState("");
  const [poolAvgLockedDay, setPoolAvgLockedDay] = useState([]);
  const [poolApr, setPoolApr] = useState([]);
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [poolTotalValueLocked, setPoolTotalLocked] = useState([]);

  const [circulatingSupplyStaked, setCirculatingSupplyStaked] = useState("");

  const poolIdForLp = 1;

  const { web3, address } = useConnectWallet();

  const onWithdraw = (pooldId, depositId) => {
    fetchWithdraw({
      address,
      web3,
      depositId,
      poolData: _.find(pools, { pid: pooldId }),
    });
  };

  useEffect(() => {
    if (web3 && address) {
      fetchDashboard({ web3, address });
      fetchPrice({ web3 });
      fetchPoolDetails();
      fetchRewardPoolDetails();
      const id = setInterval(() => {
        fetchDashboard({ web3, address });
        fetchPrice({ web3 });
        fetchPoolDetails();
        fetchRewardPoolDetails();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    let staked = detail["pools"]
      ? _.reduce(
          detail["pools"],
          function (sum, n, index) {
            let lpTotalSupply = lpData ? lpData.totalSupply : 0;
            let lpBaseReserve = lpData ? lpData.baseReserve : 0;
            let lpPrice = (lpBaseReserve * 2) / lpTotalSupply;
            let sPrice = index == 0 ? priceData : lpPrice;
            
            return sum + n.accountTotalDeposit * sPrice;
          },
          0
        )
      : "N/A";
    setUserStaked(staked);
  }, [detail, priceData]);

  useEffect(() => {
    let unclaimed = detail["pools"]
      ? _.reduce(
          detail["pools"],
          function (sum, n) {
            return sum + n.accountPendingRewards * priceData;
          },
          0
        )
      : "N/A";
    setUserUnclaimedRewards(unclaimed);
  }, [detail, priceData]);

  useEffect(() => {
    let poolTotalStaked = [];
    let poolTotalSupply = [];
    let poolLength = poolDetails ? poolDetails.length : 0;
    for (let i = 0; i < poolLength; i++) {
      poolTotalStaked.push(poolDetails[i].totalStaked);
      poolTotalSupply.push(poolDetails[i].totalSupply);
    }
    setPoolTotalStaked(poolTotalStaked);
    setPoolTotalSupply(poolTotalSupply);
  }, [poolDetails, priceData]);

  useEffect(() => {
    let totalStaked = 0;
    let poolTotalStakedLength = poolTotalStaked ? poolTotalStaked.length : 0;
    for (let i = 0; i < poolTotalStakedLength; i++) {
      if (i === poolIdForLp) {
        let lpTotalSupply = lpData ? lpData.totalSupply : 0;
        let lpTokenReserve = lpData ? lpData.tokenReserve : 0;
        totalStaked =
          parseFloat((poolTotalStaked[i] / lpTotalSupply) * lpTokenReserve) +
          parseFloat(totalStaked);
      } else {
        totalStaked = parseFloat(poolTotalStaked[i]) + parseFloat(totalStaked);
      }
    }
    let circulatingSupplyStaked = new BigNumber(totalStaked)
      .dividedBy(new BigNumber("4200000"))
      .multipliedBy(new BigNumber(100))
      .toFormat(4);
    setTotalStaked(totalStaked);
    setCirculatingSupplyStaked(circulatingSupplyStaked);
  }, [poolTotalStaked, priceData, lpData]);

  useEffect(() => {
    let poolTotalValueLocked = [];
    let poolAvgLockedDay = [];
    let poolApr = [];

    let poolTotalStakedLength = poolTotalStaked ? poolTotalStaked.length : 0;
    for (let i = 0; i < poolTotalStakedLength; i++) {
      if (i === poolIdForLp) {
        let lpTotalSupply = lpData ? lpData.totalSupply : 0;
        let lpBaseReserve = lpData ? lpData.baseReserve : 0;
        let lpPrice = (lpBaseReserve * 2) / lpTotalSupply;
        let apr =
          poolTotalStaked[i] * lpPrice < 10
            ? 0
            : (
                REWARD_PER_SECOND *
                SECONDS_PER_YEAR *
                pools[i].weight *
                priceData *
                100) /
              (poolTotalSupply[i] * lpPrice);
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * lpPrice).toFixed(2)
        );
        poolApr.push(apr);
      } else {
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * priceData).toFixed(2)
        );
        let apr =
          poolTotalStaked[i] * priceData < 10
            ? 0
            : (REWARD_PER_SECOND * SECONDS_PER_YEAR * pools[i].weight * 100) /
              poolTotalSupply[i];
        poolApr.push(apr);
      }

      let avgLockedDay = parseFloat(
        (90 * (poolTotalSupply[i] - poolTotalStaked[i])) /
          poolTotalStaked[i]
      ).toFixed(2);

      if (avgLockedDay > 0) {
        poolAvgLockedDay.push(
          parseFloat(
            (90 * (poolTotalSupply[i] - poolTotalStaked[i])) /
              poolTotalStaked[i]
          ).toFixed(2)
        );
      } else {
        poolAvgLockedDay.push(parseFloat(0).toFixed(2));
      }
    }
    setPoolTotalLocked(poolTotalValueLocked);
    setPoolAvgLockedDay(poolAvgLockedDay);
    setPoolApr(poolApr);
  }, [poolTotalStaked, poolTotalSupply, priceData, lpData]);

  useEffect(() => {
    let totalValueLocked = 0;
    let poolTotalValueLockedLength = poolTotalValueLocked
      ? poolTotalValueLocked.length
      : 0;
    for (let i = 0; i < poolTotalValueLockedLength; i++) {
      totalValueLocked =
        parseFloat(poolTotalValueLocked[i]) + parseFloat(totalValueLocked);
    }
    setTotalValueLocked(totalValueLocked);
  }, [poolTotalValueLocked, priceData]);

  useEffect(() => {
    let poolTotalValueClaimed = rewardPoolDetails
      ? parseFloat(rewardPoolDetails * priceData).toFixed(2)
      : 0;
    setTotalValueClaimed(poolTotalValueClaimed);
  }, [rewardPoolDetails, priceData]);

  useEffect(() => {
    let deposits = [];
    let poolLength = detail["pools"] ? detail["pools"].length : 0;
    for (let i = 0; i < poolLength; i++) {
      let depositsLength = detail["pools"][i].deposits.length;
      for (let j = 0; j < depositsLength; j++) {
        let userDeposit = {
          poolId: i,
          amount: detail["pools"][i].deposits[j][0],
          start: detail["pools"][i].deposits[j][1],
          end: detail["pools"][i].deposits[j][2],
          depositId: j,
        };
        deposits.push(userDeposit);
      }
    }
    setDeposits(deposits);
  }, [detail]);

  return (
    <>
      <a href="https://duet.finance/" target="_blank">
        <img
          src={banner}
          style={{ height: 400, width: "100%", objectFit: "contain" }}
        />
      </a>

      <div
        style={{
          position: "relative",
          margin: "0 auto",
          paddingTop: 40,
          maxWidth: 1100,
          minHeight: "100vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <a
                href="https://duet-protocol.gitbook.io/duet-protocol/"
                target="_blank"
                className={classes.itemLink}
              >
                <img src={gitbookIcon} className="icon" />
              </a>
            </Grid>
            <Grid item xs={2}>
              <a
                href="https://twitter.com/duetprotocol"
                target="_blank"
                className={classes.itemLink}
              >
                <img src={twitterIcon} className="icon" />
              </a>
            </Grid>
            <Grid item xs={2}>
              <a
                href="https://discord.gg/duetprotocol"
                target="_blank"
                className={classes.itemLink}
              >
                <img src={discordIcon} className="icon" />
              </a>
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1 className={classes.title}>Overview</h1>
          </Grid>
          {address ? (
            <>
              <Grid item xs={12} sm={4}>
                <div className="card" style={{ height: 220 }}>
                  <div className="cardSubHeader">Circulating Supply Staked</div>
                  <div className="cardLgValue">{circulatingSupplyStaked}%</div>
                  <br />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="card" style={{ height: 220 }}>
                  <div className="cardSubHeader">Average Lock Time</div>
                  <div className="cardLgValue">{poolAvgLockedDay[0]}</div>
                  <div className="cardSubHeader">Days</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="card" style={{ height: 220 }}>
                  <div className="cardSubHeader">Total Value Staked</div>
                  <div className="cardSubHeader">
                    $ {new BigNumber(parseFloat(totalValueLocked)).toFormat(2)}
                  </div>{" "}
                  <div className="cardSubHeader">Total Value Claimed</div>
                  <div className="cardSubHeader">
                    ${" "}
                    {new BigNumber(parseFloat(totalValuedClaimed)).toFormat(2)}
                  </div>{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card">
                  <h1 className={classes.title}>Rewards</h1>
                  <Grid container>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <img
                        src={token}
                        style={{
                          width: "30%",
                          objectFit: "contain",
                          marginBottom: 10,
                        }}
                      />
                      <div className="cardSubHeader">DUET</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card">
                  <PriceChart label={"DUET"}/>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card">
                  <div className="cardSubHeader">Staked</div>{" "}
                  <div className="cardLgValue">
                    {isNaN(userStaked)
                      ? "Loading..."
                      : "$" + parseFloat(userStaked).toFixed(2)}
                  </div>
                  <div>
                    {address ? (
                      <Button
                        color="secondary"
                        onClick={() => {
                          window.scrollTo({
                            top: computer ? 1400 : 2200,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Stake
                      </Button>
                    ) : (
                      <ConnectWallet />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card">
                  <div className="cardSubHeader">Unclaimed Rewards</div>{" "}
                  <div className="cardLgValue">
                    {isNaN(userUnclaimedRewards)
                      ? "Loading..."
                      : "$" + parseFloat(userUnclaimedRewards).toFixed(2)}
                  </div>
                  <div>
                    {address ? (
                      <Button component={Link} to={"/reward"} color="secondary">
                        Claim
                      </Button>
                    ) : (
                      <ConnectWallet />
                    )}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <h1 className={classes.title}>Pools</h1>
                <div className="card">
                  <CustomTable
                    leftText={{}}
                    headers={[
                      "Core Pools",
                      `Total Value Locked`,
                      "APR",
                      "Action",
                    ]}
                    contents={pools.map((row, index) => {
                      return [
                        row.stakedTokenName,
                        poolTotalValueLocked[index]
                          ? `$ ${new BigNumber(
                              poolTotalValueLocked[index]
                            ).toFormat(0)}`
                          : "Loading...",
                        poolApr[index] > 0
                          ? `${new BigNumber(poolApr[index]).toFormat(0)} % - ${new BigNumber(MAX_LOCKED_BOOSTED * poolApr[index]).toFormat(0)} %`
                          : "-",
                        [
                          <Button
                            color="secondary"
                            component={Link}
                            to={`/stake/${row.stakedTokenName}`}
                          >
                            Stake
                          </Button>,
                          <Button
                            color="secondary"
                            onClick={() => window.open(row.getUrl)}
                          >
                            {index == 0 ? "Buy":"Add "} {row.stakedTokenSymbol}
                          </Button>,
                        ],
                      ];
                    })}
                  />
                </div>
              </Grid>
              <Grid xs={12} item style={{ marginBottom: 20 }}>
                <h1 className={classes.title}>Deposits</h1>
                <div className="card" style={{ marginTop: 40 }}>
                  <CustomTable
                    leftText={{}}
                    headers={[
                      "Pool",
                      `Amount Staked`,
                      "Lock Date",
                      "Unlock Date",
                      "Action",
                    ]}
                    contents={userDeposits.map((row) => {
                      return [
                        pools[row.poolId].stakedTokenName,
                        Number(convertAmountFromRawNumber(row.amount)).toFixed(
                          pools[row.poolId].toFixed
                        ),
                        moment(new Date(row.start * 1000)).format(
                          "YYYY/MM/DD, HH:mm:ss"
                        ),
                        moment(new Date(row.end * 1000)).format(
                          "YYYY/MM/DD, HH:mm:ss"
                        ),
                        <Button
                          color="secondary"
                          onClick={() => {
                            onWithdraw(row.poolId, row.depositId);
                          }}
                          disabled={
                            !moment(new Date()).isAfter(
                              moment(new Date(row.end * 1000))
                            )
                          }
                        >
                          Unlock
                        </Button>,
                      ];
                    })}
                  />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <div className="card">
                  <div className="cardSubHeader">Staked</div>{" "}
                  <div className="cardLgValue">
                    {isNaN(userStaked)
                      ? "Loading..."
                      : "$" + parseFloat(userStaked).toFixed(2)}
                  </div>
                  <div>
                    {address ? (
                      <Button
                        color="secondary"
                        component={Link}
                        to={`/stake/${data.stakedTokenSymbol}`}
                      >
                        Stake
                      </Button>
                    ) : (
                      <ConnectWallet />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="card">
                  <div className="cardSubHeader">Unclaimed Rewards</div>{" "}
                  <div className="cardLgValue">
                    {isNaN(userUnclaimedRewards)
                      ? "Loading..."
                      : "$" + parseFloat(userUnclaimedRewards).toFixed(2)}
                  </div>
                  <div>
                    {address ? (
                      <Button component={Link} to={"/reward"} color="secondary">
                        Claim
                      </Button>
                    ) : (
                      <ConnectWallet />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="card">
                  <div className="cardSubHeader">Total Value Staked</div>
                  <div className="cardSubHeader">
                    $ {new BigNumber(parseFloat(totalValueLocked)).toFormat(2)}
                  </div>{" "}
                  <div className="cardSubHeader">Total Value Claimed</div>
                  <div className="cardSubHeader">
                    ${" "}
                    {new BigNumber(parseFloat(totalValuedClaimed)).toFormat(2)}
                  </div>{" "}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="card"
                  style={{ height: 200, textAlign: "center", padding: 100 }}
                >
                  <ConnectWallet />
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default Overview;
